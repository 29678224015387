import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        roles: {
            admin: "admin",
        },
        allPermissions: [
            "user",
            "safeList",
            "safeStudy",
            "safeCheck",
            "safeFind",
            "video",
            "wenzhang",
            "dept",
            "position",
            "danger",
            "danger_pendingConfirm",
            "danger_deptConfirm",
            "danger_pendingProcessing",
            "danger_pendingAcceptance",
            "danger_completed",
            "danger_rejected",
            "rule",
            "search",
            "ruleEdit",
            "ruleListEdit",
            "coalDayUse",
            "coalYard",
            "coalShip",
            "dangerType",
            "order",
            "advice",
            "hangup",
            "practice",
            "coal",
            "powerUser",
            "powerContract",
            "powerSearch",
            "powerSummary",
            "loginLog"
        ],
        users: [ // 用户列表
            {
                username: "admin",
                role: "admin",
                permissions: ["video", "coalDayUse", "coalShip", "coalYard"],
            },
            {
                username: "mtgl",
                role: "user",
                permissions: ["coalDayUse", "coalShip", "coalYard", "coal"],
            },
            {
                username: "power",
                role: "user",
                permissions: ["powerUser", "powerContract","powerSearch","powerSummary"],
            },
            {
                username: "huanglin",
                role: "user",
                permissions: ["powerUser", "powerContract","powerSearch","powerSummary","coalDayUse", "coalShip", "coalYard", "coal"],
            },
            {
                username: "xujian",
                role: "user",
                permissions: ["user",
                    "safeList",
                    "safeStudy",
                    "safeCheck",
                    "safeFind",
                    "video",
                    "wenzhang",
                    "dept",
                    "position",
                    "danger",
                    "danger_pendingConfirm",
                    "danger_deptConfirm",
                    "danger_pendingProcessing",
                    "danger_pendingAcceptance",
                    "danger_completed",
                    "danger_rejected",
                    "rule",
                    "search",
                    "ruleEdit",
                    "ruleListEdit",
                    "coalDayUse",
                    "coalYard",
                    "coalShip",
                    "dangerType",
                    "order",
                    "advice",
                    "hangup",
                    "practice",
                    "coal",
                    "loginLog"],
            },
        ],
        currentUser: null, // 当前登录用户
    },
    mutations: {
        setCurrentUser(state, username) {
            state.currentUser = state.users.find((u) => u.username === username);
        },

    },
    getters: {
        userPermissions: (state) => {
            if (state.currentUser?.role === "admin") {
                return state.allPermissions; // `admin` 自动拥有最高权限
            }
            return state.currentUser?.permissions || [];
        },
    },
    actions: {
        login({ commit }, username) {
            commit("setCurrentUser", username);
        },
    },
});
