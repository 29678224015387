<template>
  <el-menu  style="height: 100%;overflow-x: hidden;border-right-color: transparent"
           background-color="rgb(48,65,86)"
           text-color="#fff"
           active-text-color="#ffd04b"
           :collapse-transition="false"
           :collapse="isCollapse"
           router
  >
    <div style="height: 60px;line-height: 60px;text-align: center">
      <img src="../assets/logo-gx.png" style="width: 20px;position: relative;top:5px;margin-right: 5px">
      <b style="color: white" v-show="!isCollapse">国信淮电</b>
    </div>
<!--    <div v-for="menu in menuData" :key="menu.index">-->
<!--      <el-menu-item v-if="!menu.children" :index="menu.index">-->
<!--        <i :class="menu.icon"></i>-->
<!--        <span slot="title">{{ menu.title }}</span>-->
<!--      </el-menu-item>-->
<!--      <el-submenu v-else :index="menu.index">-->
<!--        <template slot="title">-->
<!--          <i :class="menu.icon"></i>-->
<!--          <span slot="title">{{ menu.title }}</span>-->
<!--        </template>-->
<!--        <div v-for="child in menu.children" :key="child.index">-->
<!--          <el-menu-item v-if="!child.children" :index="child.index">-->
<!--            <i :class="child.icon"></i>-->
<!--            <span slot="title">{{ child.title }}</span>-->
<!--          </el-menu-item>-->
<!--          <el-submenu v-else :index="child.index">-->
<!--            <template slot="title">-->
<!--              <i :class="child.icon"></i>-->
<!--              <span slot="title">{{ child.title }}</span>-->
<!--            </template>-->
<!--            <el-menu-item v-for="subChild in child.children" :key="subChild.index" :index="subChild.index">-->
<!--              <i :class="subChild.icon"></i>-->
<!--              <span slot="title">{{ subChild.title }}</span>-->
<!--            </el-menu-item>-->
<!--          </el-submenu>-->
<!--        </div>-->
<!--      </el-submenu>-->
<!--    </div>-->
    <el-menu-item index="/">

        <i class="el-icon-goods"></i>
        <span slot="title">首页</span>

    </el-menu-item>
    <el-submenu index="1">
      <template slot="title">
        <i class="el-icon-phone"></i>
        <span slot="title">随手拍</span>
      </template>
      <!-- 隐患列表及其子菜单 -->
      <el-submenu index="1-1">
        <template slot="title">
          <i class="el-icon-link"></i>
          <span slot="title">隐患列表</span>
        </template>
        <el-menu-item index="/danger">
          <i class="el-icon-document"></i>
          <span slot="title">全部</span>
        </el-menu-item>
        <el-menu-item index="/danger_pendingConfirm">
          <i class="el-icon-document"></i>
          <span slot="title">待确认</span>
        </el-menu-item>
        <el-menu-item index="/danger_deptConfirm">
          <i class="el-icon-document-checked"></i>
          <span slot="title">部门确认</span>
        </el-menu-item>
        <el-menu-item index="/danger_pendingProcessing">
          <i class="el-icon-document-copy"></i>
          <span slot="title">待处理</span>
        </el-menu-item>
        <el-menu-item index="/danger_pendingAcceptance">
          <i class="el-icon-document-checked"></i>
          <span slot="title">待验收</span>
        </el-menu-item>
        <el-menu-item index="/danger_completed">
          <i class="el-icon-check"></i>
          <span slot="title">已完成</span>
        </el-menu-item>
        <el-menu-item index="/danger_rejected">
          <i class="el-icon-close"></i>
          <span slot="title">未通过</span>
        </el-menu-item>
      </el-submenu>
    <el-menu-item index="/dangerTemp">
      <i class="el-icon-s-custom"></i>
      <span slot="title">随手拍(扫码)</span>
    </el-menu-item>
    <el-menu-item index="/order">
      <i class="el-icon-s-custom"></i>
      <span slot="title">支付列表</span>
    </el-menu-item>
    <el-menu-item index="/hangup">
      <i class="el-icon-s-data"></i>
      <span slot="title">挂起列表</span>
    </el-menu-item>
    <el-menu-item index="/dangerType">
      <i class="el-icon-s-data"></i>
      <span slot="title">类型维护</span>
    </el-menu-item>
    </el-submenu>
    <el-menu-item index="/advice">
        <i class="el-icon-link"></i>
        <span slot="title">合理化建议</span>
    </el-menu-item>
<!--    <el-menu-item index="/practice">-->
<!--      <i class="el-icon-edit"></i>-->
<!--      <span slot="title"></span>-->
<!--    </el-menu-item>-->
    <el-menu-item index="/wenzhang">
      <i class="el-icon-s-finance"></i>
      <span slot="title">公众号文章</span>
    </el-menu-item>
      <el-menu-item index="/video">
        <i class="el-icon-video-play"></i>
        <span slot="title">实操培训</span>
      </el-menu-item>
    <el-submenu index="4">
      <template slot="title">
        <i class="el-icon-alarm-clock"></i>
        <span slot="title">安全园地</span>
      </template>
      <el-menu-item index="/safeStudy">
        <i class="el-icon-link"></i>
        <span slot="title">安全学习</span>
      </el-menu-item>
      <el-menu-item index="/safeList">
        <i class="el-icon-link"></i>
        <span slot="title">安全责任</span>
      </el-menu-item>
      <el-menu-item index="/safeCheck">
        <i class="el-icon-link"></i>
        <span slot="title">安全检查</span>
      </el-menu-item>
      <el-menu-item index="/safeFind">
        <i class="el-icon-link"></i>
        <span slot="title">看图找茬</span>
      </el-menu-item>
    </el-submenu>
    <el-submenu index="5">
      <template slot="title">
        <i class="el-icon-alarm-clock"></i>
        <span slot="title">标准管理</span>
      </template>
      <el-menu-item index="/rule">
        <i class="el-icon-link"></i>
        <span slot="title">规章制度</span>
      </el-menu-item>
      <el-menu-item index="/search">
        <i class="el-icon-link"></i>
        <span slot="title">标准查询</span>
      </el-menu-item>
      <el-menu-item index="/ruleEdit">
        <i class="el-icon-link"></i>
        <span slot="title">标准管理</span>
      </el-menu-item>
      <el-menu-item index="/ruleListEdit">
        <i class="el-icon-link"></i>
        <span slot="title">标准编辑</span>
      </el-menu-item>
    </el-submenu>
    <el-submenu index="6">
      <template slot="title">
        <i class="el-icon-alarm-clock"></i>
        <span slot="title">煤碳管理</span>
      </template>
      <el-menu-item index="/coalDayUse">
        <i class="el-icon-link"></i>
        <span slot="title">每日耗用</span>
      </el-menu-item>
      <el-menu-item index="/coalYard">
        <i class="el-icon-link"></i>
        <span slot="title">煤场存煤</span>
      </el-menu-item>
      <el-menu-item index="/coalShip">
        <i class="el-icon-link"></i>
        <span slot="title">在途煤碳</span>
      </el-menu-item>
      <el-menu-item index="/coal">
        <i class="el-icon-s-finance"></i>
        <span slot="title">煤碳采购</span>
      </el-menu-item>
    </el-submenu>
    <el-submenu index="7">
      <template slot="title">
        <i class="el-icon-alarm-clock"></i>
        <span slot="title">直购电管理</span>
      </template>
      <el-menu-item index="/powerSearch">
        <i class="el-icon-link"></i>
        <span slot="title">企业查询</span>
      </el-menu-item>
      <el-menu-item index="/powerSummary">
        <i class="el-icon-link"></i>
        <span slot="title">数据统计</span>
      </el-menu-item>
      <el-menu-item index="/powerUser">
        <i class="el-icon-link"></i>
        <span slot="title">企业管理</span>
      </el-menu-item>
      <el-menu-item index="/powerContract">
        <i class="el-icon-link"></i>
        <span slot="title">签约管理</span>
      </el-menu-item>
    </el-submenu>
    <el-submenu index="8">
      <template slot="title">
        <i class="el-icon-menu"></i>
        <span slot="title">系统管理</span>
      </template>
      <el-menu-item index="/user">
        <i class="el-icon-s-custom"></i>
        <span slot="title">用户管理</span>
      </el-menu-item>
      <el-menu-item index="/dept">
        <i class="el-icon-s-shop"></i>
        <span slot="title">部门管理</span>
      </el-menu-item>
      <el-menu-item index="/position">
        <i class="el-icon-s-platform"></i>
        <span slot="title">岗位管理</span>
      </el-menu-item>
      <el-menu-item index="/loginLog">
        <i class="el-icon-location"></i>
        <span slot="title">登陆日志</span>
      </el-menu-item>
    </el-submenu>
  </el-menu>
</template>

<script>
import { mapGetters } from "vuex";
import router from "@/router";
export default {
  name: "Aside",
  props:{
    isCollapse:Boolean
  },
  // data() {
  //   return {
  //     menuData: [
  //       {
  //         index: "/",
  //         title: "首页",
  //         icon: "el-icon-goods",
  //       },
  //       {
  //         index: "1",
  //         title: "随手拍",
  //         icon: "el-icon-phone",
  //         children: [
  //           {
  //             index: "1-1",
  //             title: "隐患列表",
  //             icon: "el-icon-link",
  //             children: [
  //               { index: "/danger", title: "全部", icon: "el-icon-document" },
  //               { index: "/danger_pendingConfirm", title: "待确认", icon: "el-icon-document" },
  //               { index: "/danger_deptConfirm", title: "部门确认", icon: "el-icon-document-checked" },
  //               { index: "/danger_pendingProcessing", title: "待处理", icon: "el-icon-document-copy" },
  //               { index: "/danger_pendingAcceptance", title: "待验收", icon: "el-icon-document-checked" },
  //               { index: "/danger_completed", title: "已完成", icon: "el-icon-check" },
  //               { index: "/danger_rejected", title: "未通过", icon: "el-icon-close" },
  //             ],
  //           },
  //           { index: "/dangerTemp", title: "随手拍(扫码)", icon: "el-icon-s-custom" },
  //           { index: "/order", title: "支付列表", icon: "el-icon-s-custom" },
  //           { index: "/hangup", title: "挂起列表", icon: "el-icon-s-data" },
  //           { index: "/dangerType", title: "类型维护", icon: "el-icon-s-data" },
  //         ],
  //       },
  //       {
  //         index: "/advice",
  //         title: "合理化建议",
  //         icon: "el-icon-s-comment",
  //       },
  //       {
  //         index: "/wenzhang",
  //         title: "公众号文章",
  //         icon: "el-icon-s-finance",
  //       },
  //       {
  //         index: "/video",
  //         title: "实操培训",
  //         icon: "el-icon-video-camera",
  //       },
  //       {
  //         index: "4",
  //         title: "安全园地",
  //         icon: "el-icon-alarm-clock",
  //         children: [
  //           { index: "/safeStudy", title: "安全学习", icon: "el-icon-link" },
  //           { index: "/safeList", title: "安全责任", icon: "el-icon-link" },
  //           { index: "/safeCheck", title: "安全检查", icon: "el-icon-link" },
  //           { index: "/safeFind", title: "看图找茬", icon: "el-icon-link" },
  //         ],
  //       },
  //       {
  //         index: "5",
  //         title: "标准管理",
  //         icon: "el-icon-alarm-clock",
  //         children: [
  //           { index: "/rule", title: "规章制度", icon: "el-icon-link" },
  //           { index: "/search", title: "标准查询", icon: "el-icon-link" },
  //           { index: "/ruleEdit", title: "标准管理", icon: "el-icon-link" },
  //           { index: "/ruleListEdit", title: "标准编辑", icon: "el-icon-link" },
  //         ],
  //       },
  //       {
  //         index: "6",
  //         title: "煤碳管理",
  //         icon: "el-icon-alarm-clock",
  //         children: [
  //           { index: "/coalDayUse", title: "每日耗用", icon: "el-icon-link" },
  //           { index: "/coalYard", title: "煤场存煤", icon: "el-icon-link" },
  //           { index: "/coalShip", title: "在途煤碳", icon: "el-icon-link" },
  //           { index: "/coal", title: "煤碳采购", icon: "el-icon-s-finance" },
  //         ],
  //       },
  //       {
  //         index: "7",
  //         title: "系统管理",
  //         icon: "el-icon-menu",
  //         children: [
  //           { index: "/user", title: "用户管理", icon: "el-icon-s-custom" },
  //           { index: "/dept", title: "部门管理", icon: "el-icon-s-shop" },
  //           { index: "/position", title: "岗位管理", icon: "el-icon-s-platform" },
  //           { index: "/loginLog", title: "登录日志", icon: "el-icon-location" },
  //         ],
  //       },
  //     ],
  //   };
  // },
  // actions: {
  //   async fetchPermissions({ commit }, username) {
  //     try {
  //       const response = await axios.get(`/admin/permissions`, {
  //         params: { username },
  //       });
  //       commit("setPermissions", response.data); // 设置权限
  //     } catch (error) {
  //       console.error("获取权限失败", error);
  //     }
  //   },
  // },
  // computed: {
  //   ...mapGetters(["userPermissions"]),
  //   // 过滤菜单数据
  //   filteredMenuData() {
  //     return this.menuData.filter((menu) => {
  //       if (!menu.children) {
  //         // 单一菜单项
  //         return this.hasPermission(menu.index);
  //       } else {
  //         // 子菜单
  //         menu.children = menu.children.filter((child) => this.hasPermission(child.index));
  //         return menu.children.length > 0;
  //       }
  //     });
  //   },
  // },
  // methods: {
  //   hasPermission(index) {
  //     // 判断是否有权限
  //     const route = this.$router.options.routes.find((route) => route.path === index);
  //     if (!route || !route.meta || !route.meta.permissions) return true; // 无权限要求的默认显示
  //     return route.meta.permissions.every((perm) => this.userPermissions.includes(perm));
  //   },
  // },
}
</script>

<style scoped>

</style>